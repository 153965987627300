<template>
    <div>
   <b-modal id="investigationTable" title="Invoices" size="xl" ref="investigationTable">
    <v-client-table ref="invoiceTable" :columns="tableColumns" :data="invoiceData" :options="options" class="inv-status-table wts-table table-responsive" id="dataTable">
        <span slot="total" slot-scope="props">
          {{formatPrice(props.row.total)}}   
        </span>

        <div slot="quickpay_total" slot-scope="props">
          <span v-if="props.row.quickpay_total > 0 && $moment(props.row.endtday) > $moment()">
         {{formatPrice(props.row.quickpay_total)}}
          </span>
        </div>
        <div slot="inv_passed" slot-scope="props">
          <div v-if="props.row.accountant_paid === 0">
            <div v-if="props.row.inv_passed === 9">
              <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By WSS (Submit Again)</button>
            </div>
             <div v-if="props.row.inv_passed === 17">
                  <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Accountant</button>
            </div>

            <div v-if="props.row.inv_passed === 8 && props.row.acc_project != 1">
              <button class="btn-sm btn-warning btn-pm"><i class="fa fa-question"></i> Pending By Project Manager</button>
            </div>
            <div v-if="props.row.inv_passed === 8 && props.row.acc_project == 1">
              <button class="btn-sm btn-warning btn-pm" style="border-color: #24cabf !important; background: #24cabf !important;"><i class="fa fa-question"></i> Pending For First Approval</button>
            </div>

            <div v-if="props.row.inv_passed === 7">
              <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
            </div>

            <div v-if="props.row.inv_passed === 6">
              <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
            </div>

            <div v-if="props.row.inv_passed === 5">
              <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Project Manager (Pending Vendor)</button>
            </div>

            <div v-if="props.row.inv_passed === 0">
              <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By WSS</button>
            </div>

            <div v-if="props.row.inv_passed === 2 ">
              <button class="btn-sm btn-blue"><i class="fa fa-save"></i> Draft</button>
            </div>

            <div v-if="props.row.inv_passed === 11">
             <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
            </div>

            <div v-if="props.row.inv_passed === 13">
             <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
           </div>

            <div v-if="props.row.inv_passed === 14">
             <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
            </div>

            <div v-if="props.row.inv_passed === 21">
             <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
            </div>

            <div v-if="props.row.inv_passed === 23">
             <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By Management</button>
            </div>

            <div v-if="props.row.inv_passed === 24">
             <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
            </div>

            <div v-if="props.row.inv_passed === 31">
             <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
            </div>

            <div v-if="props.row.inv_passed === 33">
             <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By Management</button>
            </div>

            <div v-if="props.row.inv_passed === 34">
             <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
            </div>

            <div v-if="props.row.inv_passed === 41">
             <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
            </div>

            <div v-if="props.row.inv_passed === 43">
             <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By Management</button>
            </div>

            <div v-if="props.row.inv_passed === 44">
             <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
            </div>

            <div v-if="props.row.inv_passed === 51">
             <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
            </div>

            <div v-if="props.row.inv_passed === 54">
             <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
            </div>
          </div>
          <div v-if="props.row.accountant_paid === 1">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
          </div>
        </div>
        <div slot="creation_date" slot-scope="props">{{props.row.creation_date | moment('MM-DD-YYYY hh:mm A')}}</div>
        <div slot="vfname" slot-scope="props">{{ props.row.vfname }} {{ props.row.vlname }}</div>

        <div slot="invnum" slot-scope="props" v-if="role === 'manager'">
          <a href="javascript:void(0)" @click="setInvoice(props.row, 0)" v-b-modal.invoiceModal>{{props.row.invnum}}</a>
          <p class="mb-0 d-sm-block d-md-none">{{props.row.creation_date | moment('MM-DD-YYYY')}}</p>
        </div>
        <div slot="invnum" slot-scope="props" v-else>
          <a href="javascript:void(0)" @click="setInvoice(props.row, 0)" v-b-modal.wtsinvoiceModal>{{props.row.invnum}}</a>
          <p class="mb-0 d-sm-block d-md-none">{{props.row.creation_date | moment('MM-DD-YYYY')}}</p>
        </div>
      </v-client-table>
  </b-modal>
     <b-modal id="invoiceModal" ref="invoiceModal" class="invoice-modal" hide-header ok-only>
          <ManagerInvoice></ManagerInvoice>
    </b-modal>  

    <b-modal id="wtsinvoiceModal" ref="wtsinvoiceModal" size="xl" class="invoice-modal" hide-header ok-only>
          <WtsInvoice></WtsInvoice>
    </b-modal>     
        <!-- <button @click="downloadCSV()" class="btn btn-info"><i class="fa fa-download" aria-hidden="true"></i> CSV</button> -->
         <div class="row p-3" >
            <table class="table table-responsive table-bordered" >
                <tr v-bind:key="x" v-for="(row,x) in data">

                    <td v-bind:key="y" v-for="(col,y) in row">
                        <div v-if="data[x][y][0] === '$ 0.00'"> <span style="color:lightgray"> {{data[x][y][0]}}  </span>  </div>
                        <div v-if="data[x][y][0] != '$ 0.00'"  style="margin-left:0px"> 
                            <span style="font-weight:bold" id="total-box" v-if="x !== 0 && y !== 0" @click="getInvoiceList(data[x][y][1], data[x][y][0])"> {{data[x][y][0]}} </span>
                            <span style="font-weight:bold" v-else> {{data[x][y]}}</span>
                        </div>                              
                    </td>
                </tr>
            </table>
        </div>

        <!-- new table with 2 headers ONLY -->
        
        <!-- end of new table with 2 headers ONLY -->

    </div>    
</template>
          
<script>
import Vue from 'vue'
import {Multiselect} from 'vue-multiselect';
import {ClientTable} from 'vue-tables-2';
import ManagerInvoice from '../views/manager/ManagerInvoice.vue';
import WtsInvoice from '../views/wts/WtsInvoice.vue';
Vue.use(ClientTable)

export default {
    name : 'InvestigationTableNew',
    components : {
    Multiselect,
    ClientTable,
    ManagerInvoice,
    WtsInvoice
    },
    props: ['rows','columns','data', 'role'],
    data (){
      console.log("rows",this.rows);
      console.log("columns",this.columns);
      console.log("role",this.role);
      console.log("role",this.data);
        return {
        tableColumns: ['inv_passed', 'invnum', 'ticketnum', 'vendor_invoice_number', 'vcn', 'vfname', 'creation_date', 'total', 'quickpay_total'],
        options: {
        headings: {
          inv_passed: 'Status',
          invnum: 'Invoice #',
          vcn: 'Company Name',
          creation_date: 'Submitted Date',
          vcn: 'Company Name',
          vfname: 'Vendor Name',
          quickpay_total: 'Quickpay Total',
          ticketnum: 'Field Ticket #',
          vendor_invoice_number: 'Vendor Invoice #'
        },
      },
        invoiceData:[],
        invoicestotal:0
        }
    },

    // computed : {
    //     tableData : function(){
    //       console.log("pivot data look like this mhn!",this.data);
    //         if(this.rows.length < 0 && this.columns.length < 0 ) 
    //             return;

    //         let tableData = this.data;          //
    //         //  Limit length of columns and rows to 25
    //         //

    //         for(let y = 0 ; y < this.columns.length ; y++ ){
    //             if(this.columns[y].length > 35){
    //                 this.columns[y] = this.columns[y].substring(0,23) + '...';
    //             }
                
    //         }

    //         for(let y = 0 ; y < this.rows.length ; y++ ){
    //             if(this.rows[y].length > 35){
    //                 this.rows[y] = this.rows[y].substring(0,23) + '...';
    //             }
    //         }      

    //         //
    //         //  Add all the column headers to the front of each array 
    //         //  Columns headers found in column prop
    //         //
    //         //
    //         //  Convert:
    //         //
    //         //  [1,2,5]
    //         //  [3,4,6]
    //         //  [2,4,5]
    //         //
    //         //  To:
    //         //
    //         //  [COL1HEADER ,1,2,5]
    //         //  [COL2HEADER ,3,4,6]
    //         //  [COL3HEADER ,2,4,5]
    //         //
    //         //
    //         for(let y = 0 ; y < this.columns.length ; y++ ){
    //             tableData[y].unshift(this.columns[y]);
    //         }

    //         //
    //         //  Populate headers row
    //         //  Headers found in rows prop
    //         //
    //         //  
    //         //  Convert     
    //         //
    //         //  [COL1HEADER ,1,2,5]
    //         //  [COL2HEADER ,3,4,6]
    //         //  [COL3HEADER ,2,4,5]
    //         //
    //         //  To:
    //         //  [  -blank-  ,    HEADER1,    HEADER2,   HEADER3]
    //         //  [COL1HEADER ,   1,          2,          5]
    //         //  [COL2HEADER ,   3,          4,          6]
    //         //  [COL3HEADER ,   2,          4,          5]
    //         //
    //         //

    //         this.rows.unshift('');  //Insert a blank row in the front
    //         tableData.unshift(this.rows);
    //         console.log("pivot data after all calculations look like this mhn!",tableData);
    //         return tableData;
    //     }
    // },
    methods : {
        getInvoiceList(data) {
        this.invoiceData = [];
        data = this.lodash.uniq(data, 'invoiceid');
        this.$axios.post('/wts/get/invoice/inv/', data)
            .then(response => {
                this.invoiceData = response.data.result;
                this.invoicestotal = this.invoiceData.reduce((acc, val) => {
                    return Number(acc) + Number(val.total);
                }, 0);
                this.$refs['investigationTable'].show();
            }).catch(() => {});
        },
        downloadCSV(){
          var csvRows = [];
          let dataArray = this.data;
          for (var i = 0; i < dataArray.length; ++i) {
              for (var j = 0; j < dataArray[i].length; ++j) {
                if(i == 0 || j == 0) {
                    dataArray[i][j] = '\"' + dataArray[i][j] + '\"'; // Handle elements that contain commas
                    dataArray[i][j] = dataArray[i][j];
                } else {
                    dataArray[i][j] = dataArray[i][j][0].replace('$','').replaceAll(',', ''); // Handle elements that contain commas
                } 
              }
              csvRows.push(dataArray[i].join(','));
            }
          var csvString = csvRows.join('\r\n');           
          var a         = document.createElement('a');
          a.href        = 'data:attachment/csv,' + csvString;
          a.target      = '_blank';
          a.download    = 'InvestigationsTable.csv';

          document.body.appendChild(a);3
          a.click();  
        }
    }
}
</script>

<style scoped>
    #total-box:hover {
     cursor: pointer;
    }
    
    td:nth-child(1){
        width:20%;
    }

    tr:nth-child(1),
    td:nth-child(1){
        background-color: #444;
        color: white; 
        font-size: 0.85em;
        font-weight: bold;   
    }

    tr:nth-child(1){
        max-width: 100px;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
    }

    td:nth-child(1){
        white-space: nowrap;  
    }
    
</style>
                