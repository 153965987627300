<template>
  <div class="animated fadeIn">
    <b-card>
      <h4>
        <i class="fa fa-table" aria-hidden="true"></i> Investigation Table
      </h4>
      <hr />

      <div class="row">
        <div class="col-sm-3">
          <h5>Select Rows</h5>
          <multiselect
            label="name"
            v-model="filters.row.selectedOption"
            :options="filters.row.options"
            :multiple="false"
            :close-on-select="true"
            deselect-label=""
            :allowEmpty="false"
            selectLabel=""
            openDirection="bottom"
            track-by="id"
            @input="selectionChangedRow($event)"
          ></multiselect>
        </div>
        <div class="col-sm-1 text-center"><h2 class="mt-3 pt-3">VS</h2></div>
        <div class="col-sm-3">
          <h5 class="vs-text">Select Columns</h5>
          <multiselect
            label="name"
            v-model="filters.column.selectedOption"
            :options="filters.column.options"
            :multiple="false"
            :close-on-select="true"
            deselect-label=""
            :allowEmpty="false"
            selectLabel=""
            openDirection="bottom"
            track-by="id"
            @input="selectionChangedColumn($event)"
          ></multiselect>
          <!-- <multiselect v-model="xAxisDim2Selection" :options="xAxisDim2Options" :multiple="false" :close-on-select="true" deselect-label='' :allowEmpty="false" selectLabel='' @input="selectionChanged('xAxisDim2')" ></multiselect> -->
        </div>
        <div class="col-sm-1 text-center">
          <i class="fas fa-arrow-circle-right fa-2x mt-3 pt-3"></i>
        </div>
        <div class="col-sm-3">
          <h5>Select Value</h5>
          <multiselect
            v-model="filters.finalValue.selectedOption"
            :options="filters.finalValue.options"
            :multiple="false"
            :close-on-select="true"
            deselect-label=""
            :allowEmpty="false"
            selectLabel=""
            openDirection="bottom"
            :hide-selected="true"
            @input="selectionChanged()"
          ></multiselect>
          <div
            v-if="filters.dataSelection === 'Total With Discount'"
            class="float float-right"
          >
            Factor 5% GST
            <!-- <toggle-button
              @change="toggleGST()"
              v-model="calcGST"
              :value="true"
              :labels="{ checked: 'GST', unchecked: '' }"
            /> -->
          </div>
        </div>
      </div>

      <!-- New Select sub row and col -->
      <div
        v-if="
          filters.row.selectedOption.name || filters.column.selectedOption.name
        "
        class="row mt-2"
      >
        <div class="col-sm-3">
          <span v-if="filters.row.showSubRow">
            <h5>Select {{ filters.row.selectedOption.name }}</h5>
            <multiselect
              label="name"
              v-model="filters.row.subOptions.selectedOption"
              :options="filters.row.subOptions.options"
              :multiple="true"
              deselect-label=""
              openDirection="bottom"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="true"
              @input="selectionChanged()"
              @search-change="rowSearchChanged($event)"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }}
                  {{ filters.row.selectedOption.name }} selected</span
                ></template
              >
            </multiselect>
          </span>
          <!-- @remove="onRemoveRow($event)" -->
        </div>
        <div class="col-sm-1 text-center"><h2 class="mt-3 pt-3"></h2></div>
        <div class="col-sm-3">
          <span v-if="filters.column.showSubColumn">
            <h5 class="vs-text">
              Select {{ filters.column.selectedOption.name }}
            </h5>
            <multiselect
              label="name"
              v-model="filters.column.subOptions.selectedOption"
              :options="filters.column.subOptions.options"
              :multiple="true"
              openDirection="bottom"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="true"
              @input="selectionChanged()"
              @search-change="columnSearchChanged($event)"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }}
                  {{ filters.column.selectedOption.name }} selected</span
                ></template
              >
            </multiselect>
          </span>
          <!-- <multiselect v-model="xAxisDim2Selection" :options="xAxisDim2Options" :multiple="false" :close-on-select="true" deselect-label='' :allowEmpty="false" selectLabel='' @input="selectionChanged('xAxisDim2')" ></multiselect> -->
        </div>
      </div>

      <!-- end of New Select sub row and col -->

      <div class="row mt-2">
        <div class="col-sm-3">
          <h5>Select Status</h5>
          <!-- @input="filterInvoices()" -->

          <multiselect
            v-model="filters.status.selectedOption"
            :options="filters.status.options"
            :multiple="true"
            :close-on-select="false"
            deselect-label=""
            :allowEmpty="true"
            selectLabel=""
          ></multiselect>
        </div>
        <!-- <div class="col-sm-3" v-if="status.includes('Vendor invoice Date')">
          <h5>Apply Vendor Invoice Date</h5>
          <v-date-picker
            class="vc-calendar date-padding"
            :formats="formats"
            mode="range"
            v-model="vendor_invoice_date"
            show-caps
          >
          </v-date-picker>
        </div> -->
        <!-- <div class="col-sm-3" v-if="status.includes('Invoice Service Date')">
          <h5>Apply Vendor Service Date</h5>
          <v-date-picker
            class="vc-calendar date-padding"
            :formats="formats"
            mode="range"
            v-model="vendor_service_date"
            show-caps
          >
          </v-date-picker>
        </div> -->
      </div>

      <div class="row mt-3">
        <div class="col-sm-12">
          <button @click="buildTable()" class="btn btn-primary">
            <i class="fas fa-analytics"></i> Build Investigation Table
          </button>

          <button
            v-if="displayPivotTable"
            @click="refreshData()"
            class="btn btn-info ml-3"
          >
            <i class="fas fa-sync"></i> Refresh Data
          </button>
          <button @click="clearSelection()" class="btn btn-danger ml-3">
            <i class="fa fa-trash" aria-hidden="true"></i> Clear
          </button>
          <!-- <button
            v-if="vendor_service_date || vendor_invoice_date"
            @click="
              vendor_service_date = null;
              vendor_invoice_date = null;
            "
            class="btn btn-danger ml-3"
          >
            <i class="fas fa-eraser"></i> Clear Dates
          </button> -->
          <hr />
        </div>
      </div>

      <div v-if="loading" class="row animated fadeIn">
        <div class="col-sm-12 text-center">
          <RotateSquare2 style="margin-left: 47%"></RotateSquare2>
          Loading Investigation Table Data...
        </div>
      </div>

      <div v-if="displayPivotTable" class="row p-3 mt-3 mb-3">
        <div class="col-md-6">
          <h5><i class="fa fa-filter" aria-hidden="true"></i> Row Filter</h5>
          <!-- <multiselect
            :multiple="true"      
            v-model="rowFilterSelection"
            :options="rowFilterOptions"
            deselect-label=""
            :allowEmpty="false"
            selectLabel=""
            @remove="onRemoveRow"
            @select="onSelectRow"
          ></multiselect> -->
        </div>
        <div class="col-md-6">
          <h5><i class="fa fa-filter" aria-hidden="true"></i> Column Filter</h5>
          <!-- <multiselect
            :multiple="true"
            v-model="columnFilterSelection"
            :options="columnFilterOptions"
            deselect-label=""
            :allowEmpty="false"
            selectLabel=""
            @remove="onRemoveColumn"
            @select="onSelectColumn"
          ></multiselect> -->
        </div>
      </div>

      <div v-if="totalInvoices > 0">
        <div
          style="
            position: relative;
            bottom: 33px;
            right: 16px;
            margin-top: 30px;
          "
        >
          <button
            @click="generateCSV()"
            style="position: absolute; right: 0"
            class="btn btn-info"
          >
            <i class="fa fa-download" aria-hidden="true"></i> CSV
          </button>
        </div>
        <!-- <hr /> -->
        <!-- <v-server-table
          url="/manager/get/invoice_services/get_investigation_table_data_new"
          :columns="table.columns"
          :options="table.options"
        /> -->
        <div class="col-lg-12" id="investigationTable">
          <InvestigationTableNew
            v-bind:rows="data.rows"
            v-bind:role="'manager'"
            v-bind:columns="data.columnsData"
            v-bind:data="pivotData"
            :class="{ disabledTable: showLoading }"
          >
          </InvestigationTableNew>
          <div>
            <pagination
              v-model="page"
              :per-page="10"
              :records="totalInvoices"
              @paginate="buildTable"
            />
          </div>
          <!-- <v-client-table
            :data="t.data.tableData"
            :columns="t.data.columns"
            :options="t.data.options"
          /> -->
          <!-- <table class="table table-responsive">
            <thead>
              <tr>
                <th scope="col" class="text-center name"></th>
                <th
                  v-for="row in data.rows"
                  :key="row"
                  scope="col"
                  class="text-center name"
                >
                  {{ row }}
                </th>
              </tr>
            </thead>
            <tbody>
              
              <td></td>
              <th v-for="(row, index) in data.rows" :key="index">
                
                <tr
                  v-for="(columns, index2) in data.columns[index]"
                  :key="index2"
                >
                
                  <td>{{ columns }}</td>
                </tr>
              </th>
            </tbody>
          </table> -->
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import { Multiselect } from "vue-multiselect";
import { ServerTable, ClientTable, Event } from "vue-tables-2";

import { RotateSquare2 } from "vue-loading-spinner";
import InvestigationTableNew from "./../../components/InvestigationTableNew";
import { ToggleButton } from "vue-js-toggle-button";
import moment from "moment";
import { extendMoment } from "moment-range";
// const APIS = require("../../services/APIS");
import APIS from "../../services/APIS";
import Pagination from "vue-pagination-2";

const moments = extendMoment(moment);
Vue.use(ServerTable, {}, false, "bootstrap4");

const API = new APIS();
/* Vue.use(ClientTable) */

export default {
  name: "AccoutantPivotTable",
  components: {
    Multiselect,
    InvestigationTableNew,
    RotateSquare2,
    ToggleButton,
    Pagination,
  },
  props: [
    "filterSelection",
    "masterInvoiceProjectSelection",
    "masterInvoiceAfeSelection",
    "masterInvoiceVendorSelection",
  ],
  data() {
    return {
      page: 1,
      totalInvoices: 0,
      showLoading: false,
      showCSVLoading: false,
      displayPivotTable: false,
      filters: {
        row: {
          showSubRow: false,
          options: [
            { id: 1, name: "Vendor" },
            { id: 2, name: "Line Item" },
            { id: 3, name: "Project" },
            { id: 10, name: "Days" },
            { id: 10, name: "Months" },
            { id: 10, name: "Years" },
          ],
          selectedOption: "",
          subOptions: {
            options: [],
            selectedOption: "",
          },
        },
        column: {
          showSubColumn: false,
          options: [
            { id: 5, name: "AFE / PO" },
            { id: 6, name: "Category" },
            //  "Project",
            { id: 7, name: "Cost Code #1 / Major" },
            { id: 8, name: "Cost Code #2 / Minor" },
            { id: 9, name: "Cost Code #3 / Description" },
            { id: 4, name: "Well" },
            // {id:7,name:"Line Item"},
          ],
          selectedOption: "",
          subOptions: {
            options: [],
            selectedOption: "",
          },
        },
        finalValue: {
          selectedOption: "",
          options: ["Total With Discount"],
        },
        status: {
          options: [
            "Pending By Wss",
            "Pending By Higher Managment",
            "Pending By Project Manager",
            "AP Team Review",
            "Qbyte Processed",
            "Vendor invoice Date",
            "Invoice Service Date",
          ],
          selectedOption: [],
        },
      },
      loading: false,
      data: {
        rows: [
          //   "Thorhild-Radway 09-22-059-20W4",
          //   "Thorhild-Radway 09-22-059-20W4",
          //   "Thorhild-Radway 09-22-059-20W4",
          //   "Thorhild-Radway 09-22-059-20W4",
          //   "KRC Shared cost project",
          //   "Clearwater 4-24 Drilling",
          //   "WILLESDEN",
          //   "WASKAHIGAN",
          //   "SIMONETTE",
          //   "Clearwater 4-24 Production Facilities",
        ],
        columns: [
          //   [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [2, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [3, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [4, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [6, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [7, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [8, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          //   [9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        ],
        columnsData: [
          //   "Facility",
          //   "Production",
          //   "SIMONETTE",
          //   "WILLESDEN_C",
          //   "KRC Shared cost project_C",
          //   "Drilling",
          //   "WILLESDEN_C",
          //   "WASKAHIGAN",
          //   "SIMONETTE",
          //   "Production Facilities - SURFACE",
        ],
      },
      pivotData: [],
      formats: {
        title: "MMMM YYYY",
        weekdays: "W",
        navMonths: "MMM",
        input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
        dayPopover: "L",
      },
    };
  },
  methods: {
    // filter invoice for selected status
    filterInvoices() {
      this.code = [];
      if (this.status.includes("Pending By Wss")) {
        this.code.push(0);
      }
      if (this.status.includes("Pending By Higher Managment")) {
        this.code.push(6, 13, 23, 33, 43);
      }
      if (this.status.includes("Pending By Project Manager")) {
        this.code.push(8);
      }
      if (this.status.includes("AP Team Review")) {
        this.code.push(7, 11, 21, 31, 41, 51);
      }
      if (this.status.includes("Qbyte Processed")) {
        this.code.push(1);
      }
    },
    selectionChanged() {
      // console.log("filters", JSON.parse(JSON.stringify(this.filters)));
    },
    //on main row selection fetch api on the basic of id
    async selectionChangedRow({ id, name }) {
      this.filters.row.subOptions.options = [];
      this.filters.row.subOptions.selectedOption = [];
      this.filters.row.showSubRow = id === 1 || id === 2 || id === 3;
      if (this.filters.row.showSubRow) {
        const response = await this.getDropDown(id);
        console.log("selectionChangedRow:res", response);
        this.filters.row.subOptions.options = response.data;
      }
    },
    async rowSearchChanged(event) {
      const response = await this.getDropDown(
        this.filters.row.selectedOption.id,
        event
      );
      this.filters.row.subOptions.options = response.data;
    },
    async selectionChangedColumn({ id, name }) {
      this.filters.column.subOptions.options = [];
      this.filters.column.subOptions.selectedOption = [];
      this.filters.column.showSubColumn = true;
      this.filters.column.showSubColumn = id === 4 || id === 5 || id === 6;
      if (this.filters.column.showSubColumn) {
        const response = await this.getDropDown(id);
        this.filters.column.subOptions.options = response.data;
      }
    },
    async columnSearchChanged(event) {
      const response = await this.getDropDown(
        this.filters.column.selectedOption.id,
        event
      );
      this.filters.column.subOptions.options = response.data;
    },
    async buildTable(page) {
      if (this.checkErrors()) {
        return;
      }
      if (!page) {
        this.loading = true;
      }
      // loading
      page = page ? page : 1;
      const query = this.buildQuery();
      this.showLoading = true;
      const { data } = await this.$axios.post(
        `/manager/get/invoice_services/get_investigation_table_data_new?page=${page}`,
        query
      );
      // generateInvestigationTableDataCSV
      this.showLoading = false;
      this.loading = false;
      this.pivotData = data.pivotData;
      this.totalInvoices = data.total;
    },
    async generateCSV() {
      const query = this.buildQuery();
      this.showCSVLoading = true;
      const { data } = await this.$axios.post(
        `/manager/get/invoice_services/generateInvestigationTableDataCSV`,
        query
      );
      let csvRows = [];
      let dataArray = data.pivotData;
      for (let i = 0; i < dataArray.length; ++i) {
        for (let j = 0; j < dataArray[i].length; ++j) {
          if (i == 0 || j == 0) {
            dataArray[i][j] = '"' + dataArray[i][j] + '"'; // Handle elements that contain commas
            dataArray[i][j] = dataArray[i][j];
          } else {
            dataArray[i][j] = dataArray[i][j][0]
              .replace("$", "")
              .replaceAll(",", ""); // Handle elements that contain commas
          }
        }
        csvRows.push(dataArray[i].join(","));
      }
      let csvString = csvRows.join("\r\n");
      let a = document.createElement("a");
      a.href = "data:attachment/csv," + csvString;
      a.target = "_blank";
      a.download = "InvestigationsTable.csv";
      document.body.appendChild(a);
      3;
      a.click();
      this.showCSVLoading = false;
    },
    buildQuery() {
      console.log("this.filters", JSON.parse(JSON.stringify(this.filters)));
      return {
        row: this.filters.row.selectedOption,
        rowSubOptions: this.filters.row.subOptions.selectedOption,
        column: this.filters.column.selectedOption,
        columnSubOptions: this.filters.column.subOptions.selectedOption,
        finalValue: this.filters.finalValue.selectedOption,
        status: this.filters.status.selectedOption,
      };
    },
    checkErrors() {
      if (this.filters.row.selectedOption.length === 0) {
        this.$toasted.show("Please select xAxis", {
          type: "error",
          duration: "3000",
        });
        return true;
      }
      if (this.filters.column.selectedOption.length === 0) {
        this.$toasted.show("Please select yAxis", {
          type: "error",
          duration: "3000",
        });
        return true;
      }
    },
    refreshData() {},
    clearSelection() {
      this.filters.row.selectedOption = "";
      this.filters.row.showSubRow = false;
      this.filters.column.showSubColumn = false;
      this.filters.row.subOptions.selectedOption = [];
      this.filters.column.selectedOption = "";
      this.filters.column.subOptions.selectedOption = [];
      this.filters.finalValue.selectedOption = "";
      this.filters.status.selectedOption = [];
    },
    async getDropDown(id, name = "") {
      const reqURLS = {
        1: "/venders",
        2: "/services",
        3: "/projects",
        4: "/projectWells",
        5: "/projectAFEs",
        6: "/projectcategories",
      };
      //         { id: 1, name: "Vendor" },
      //   { id: 2, name: "Line Item" },
      //   { id: 3, name: "Project" },
      //   { id: 10, name: "Days" },
      //   { id: 10, name: "Months" },
      //   { id: 10, name: "Years" },
      // ]
      //   { id: 4, name: "Well" },
      //   { id: 5, name: "AFE / PO" },
      //   { id: 6, name: "Category" },
      //   //  "Project",
      //   { id: 7, name: "Cost Code #1 / Major" },
      //   { id: 8, name: "Cost Code #2 / Minor" },
      //   { id: 9, name: "Cost Code #3 / Description" },
      return await this.$axios.get(
        `/manager/dropdowns${reqURLS[id]}?name=${name}`
      );
    },

    //     async columnSearchChanged(event) {
    //   const response = await this.getDropDown(
    //     this.filters.column.selectedOption.id,
    //     event
    //   );
    //   this.filters.column.subOptions.options = response.data;
    // },
    // removeArrayElement(array,id) {
    //   const removeIndex = array.map((item) => item.id).indexOf(id);
    //   return removeIndex && array.splice(removeIndex, 1);
    // },
    //   onRemoveRow({id}) {
    //   console.log(
    //     "onRemoveRow:event",
    //     JSON.parse(JSON.stringify(this.filters))
    //   );
    // },
  },
  mounted() {},
};
</script>

<style>
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #378e43;
  border-color: #378e43;
}
.page-link:hover {
  cursor: pointer;
}
.pagination {
  float: right;
  margin-top: -11px;
}
.disabledTable {
  pointer-events: none;
  opacity: 0.78;
}
</style>
