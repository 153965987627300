const axios = require("axios").default;
// axios.create({ baseURL: "", headers: { Authorization :""} });
export default class ApiService {
  rootURL = "/manager";
  // this.$session.set("dataFetched", false);
  async getInvestigationTableDropDowns(type) {
    const reqURLS = {
      1: "/services",
      2: "/venders",
      3: "/projects",
      4: "/projectWells",
      5: "/projectAFEs",
      6: "/projectcategories",
    };
    return axios.get(rootURL+reqURLS[type]);
  }
}

// const APIS = new ApiService();
// export default APIS;
