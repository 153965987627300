var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "investigationTable",
          attrs: { id: "investigationTable", title: "Invoices", size: "xl" }
        },
        [
          _c("v-client-table", {
            ref: "invoiceTable",
            staticClass: "inv-status-table wts-table table-responsive",
            attrs: {
              columns: _vm.tableColumns,
              data: _vm.invoiceData,
              options: _vm.options,
              id: "dataTable"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "total",
                  fn: function(props) {
                    return _c("span", {}, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.formatPrice(props.row.total)) +
                          "   \n      "
                      )
                    ])
                  }
                },
                {
                  key: "quickpay_total",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.quickpay_total > 0 &&
                      _vm.$moment(props.row.endtday) > _vm.$moment()
                        ? _c("span", [
                            _vm._v(
                              "\n       " +
                                _vm._s(
                                  _vm.formatPrice(props.row.quickpay_total)
                                ) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "inv_passed",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.accountant_paid === 0
                        ? _c("div", [
                            props.row.inv_passed === 9
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(" Rejected By WSS (Submit Again)")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 17
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(" Rejected By Accountant")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 8 &&
                            props.row.acc_project != 1
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-sm btn-warning btn-pm"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By Project Manager")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 8 &&
                            props.row.acc_project == 1
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-sm btn-warning btn-pm",
                                      staticStyle: {
                                        "border-color": "#24cabf !important",
                                        background: "#24cabf !important"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending For First Approval")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 7
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 6
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-sm btn-warning btn-management"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By Higher Management")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 5
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Project Manager (Pending Vendor)"
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 0
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By WSS")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 2
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-blue" },
                                    [
                                      _c("i", { staticClass: "fa fa-save" }),
                                      _vm._v(" Draft")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 11
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 13
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-sm btn-warning btn-management"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By Higher Management")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 14
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending Vendor)"
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 21
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 23
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By Management")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 24
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending Vendor)"
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 31
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 33
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By Management")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 34
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending Vendor)"
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 41
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 43
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By Management")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 44
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending Vendor)"
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 51
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 54
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending Vendor)"
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      props.row.accountant_paid === 1
                        ? _c("div", [
                            _c(
                              "button",
                              { staticClass: "btn-sm btn-success" },
                              [
                                _c("i", { staticClass: "fa fa-check" }),
                                _vm._v(" Paid")
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "creation_date",
                  fn: function(props) {
                    return _c("div", {}, [
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(
                            props.row.creation_date,
                            "MM-DD-YYYY hh:mm A"
                          )
                        )
                      )
                    ])
                  }
                },
                {
                  key: "vfname",
                  fn: function(props) {
                    return _c("div", {}, [
                      _vm._v(
                        _vm._s(props.row.vfname) +
                          " " +
                          _vm._s(props.row.vlname)
                      )
                    ])
                  }
                },
                {
                  key: "invnum",
                  fn: function(props) {
                    return _vm.role === "manager"
                      ? _c("div", {}, [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.invoiceModal",
                                  modifiers: { invoiceModal: true }
                                }
                              ],
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.setInvoice(props.row, 0)
                                }
                              }
                            },
                            [_vm._v(_vm._s(props.row.invnum))]
                          ),
                          _c(
                            "p",
                            { staticClass: "mb-0 d-sm-block d-md-none" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    props.row.creation_date,
                                    "MM-DD-YYYY"
                                  )
                                )
                              )
                            ]
                          )
                        ])
                      : _c("div", {}, [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.wtsinvoiceModal",
                                  modifiers: { wtsinvoiceModal: true }
                                }
                              ],
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.setInvoice(props.row, 0)
                                }
                              }
                            },
                            [_vm._v(_vm._s(props.row.invnum))]
                          ),
                          _c(
                            "p",
                            { staticClass: "mb-0 d-sm-block d-md-none" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    props.row.creation_date,
                                    "MM-DD-YYYY"
                                  )
                                )
                              )
                            ]
                          )
                        ])
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "invoiceModal",
          staticClass: "invoice-modal",
          attrs: { id: "invoiceModal", "hide-header": "", "ok-only": "" }
        },
        [_c("ManagerInvoice")],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "wtsinvoiceModal",
          staticClass: "invoice-modal",
          attrs: {
            id: "wtsinvoiceModal",
            size: "xl",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [_c("WtsInvoice")],
        1
      ),
      _c("div", { staticClass: "row p-3" }, [
        _c(
          "table",
          { staticClass: "table table-responsive table-bordered" },
          _vm._l(_vm.data, function(row, x) {
            return _c(
              "tr",
              { key: x },
              _vm._l(row, function(col, y) {
                return _c("td", { key: y }, [
                  _vm.data[x][y][0] === "$ 0.00"
                    ? _c("div", [
                        _c("span", { staticStyle: { color: "lightgray" } }, [
                          _vm._v(" " + _vm._s(_vm.data[x][y][0]) + "  ")
                        ])
                      ])
                    : _vm._e(),
                  _vm.data[x][y][0] != "$ 0.00"
                    ? _c("div", { staticStyle: { "margin-left": "0px" } }, [
                        x !== 0 && y !== 0
                          ? _c(
                              "span",
                              {
                                staticStyle: { "font-weight": "bold" },
                                attrs: { id: "total-box" },
                                on: {
                                  click: function($event) {
                                    return _vm.getInvoiceList(
                                      _vm.data[x][y][1],
                                      _vm.data[x][y][0]
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.data[x][y][0]) + " ")]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(" " + _vm._s(_vm.data[x][y]))]
                            )
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }